import Mixin from "@ember/object/mixin";
import Eyeline from "discourse/lib/eyeline";
import Scrolling from "discourse/mixins/scrolling";
import { on } from "discourse-common/utils/decorators";
export default Mixin.create(Scrolling, {
  // 当页面被滚动时调用此方法
  scrolled() {
    // const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // if(isMobile){
    //   return this.eyeline?.update();
    // }else{
      // var fullUrl = window.location.href;
      // const path = new URL(fullUrl).pathname;
      // if(path=='/' ||path=='/latest' || path=='/latest' ||path=='/top'){
      // }else{
      // return this.eyeline?.update();
      // }
    // }
  },

  // 使用装饰器 @on("didInsertElement") 来确保在元素插入后调用
  @on("didInsertElement")
  _bindEyeline() {
    const eyeline = Eyeline.create({
      selector: `${this.eyelineSelector}:last`,
    });

    this.set("eyeline", eyeline);
    eyeline.on("sawBottom", () => this.send("loadMore"));
    eyeline.update();

    this.bindScrolling();

    // 获取加载更多按钮并绑定点击事件
    const button = document.getElementById("load-more-button");
    if (button) {
      button.addEventListener("click", () =>{
        var fullUrl = window.location.href;
        const path = new URL(fullUrl).pathname;
        if(path=='/' ||path=='/latest' ||path=='/top'){
        }else{
          this.send("loadMore")
        }
      });
    }
  },

  // 使用装饰器 @on("willDestroyElement") 来确保在元素销毁前调用
  @on("willDestroyElement")
  _removeEyeline() {
    this.unbindScrolling();

    // 获取加载更多按钮并移除点击事件
    const button = document.getElementById("load-more-button");
    if (button) {
      button.addEventListener("click", () =>{
        var fullUrl = window.location.href;
        const path = new URL(fullUrl).pathname;
        if(path=='/' ||path=='/latest' ||path=='/top'){
        }else{
          this.send("loadMore")
        }
      });
    }
  },

  // actions: {
  //   loadMore() {
  //     console.log("Load more items");
  //     // 在这里实现加载更多内容的逻辑
  //   },
  // },
});
