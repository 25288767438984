/* import __COLOCATED_TEMPLATE__ from './sharewx-modal.hbs'; */
import Component from "@ember/component";
import { tracked } from "@glimmer/tracking";
import { getAbsoluteURL } from "discourse-common/lib/get-url";
import loadScript from "discourse/lib/load-script";

export default class Sharewxmodal extends Component {
  @tracked qrcodeloading = true;
  @tracked qrCodeUrl = '';

  init() {
    super.init(...arguments);

    // 确保在代码中使用 absolute URL
    let codeurl = getAbsoluteURL(this.model.topicUrl);
    console.log(codeurl);

    // 动态加载 jQuery 和插件
    loadScript("/cdn/jquery.min.js")
      .then(() => {
        console.log(this);
        // const newJQuery = jQuery.noConflict(true); // 防止覆盖全局 jQuery

        return loadScript("/cdn/jquery.qrcode.min.js").then(() => {
          setTimeout(() => {
            let code=$('#qrcode')
            code.empty();
            code.qrcode(codeurl);
            this.qrcodeloading = false;
          }, 500);
        });
      })
      .catch(error => {
        console.error('Failed to load scripts', error);
      });
  }
}
