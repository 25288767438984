import { ajax } from "discourse/lib/ajax";
export async function  translation(res) {
    let data={
      content:res.content,
      to:res.to,
      from:res.from,
    }
  let result= await ajax(
      `/moyun/hw_gpt`,
      {
        type: "POST",
        data,
        timeout: 60000,
      }
    )
    return result.result.translated_text
}
