import { h } from "virtual-dom";
import DecoratorHelper from "discourse/widgets/decorator-helper";
import hbs from "discourse/widgets/hbs-compiler";
import PostCooked from "discourse/widgets/post-cooked";
import { createWidget } from "discourse/widgets/widget";
import formatDate  from "discourse/helpers/format-date";
import { transformBasicPost } from "discourse/lib/transform-post";
import { postTransformCallbacks } from "discourse/widgets/post-stream";
import Site from "discourse/models/site";
import ActionSummary from "discourse/models/action-summary";
import EmberObject from "@ember/object";
import I18n from "discourse-i18n";

function transformWithCallbacks(post) {
  let transformed = transformBasicPost(post);
  postTransformCallbacks(transformed);
  return transformed;
}

createWidget("post-link-arrow", {
  tagName: "div.post-link-arrow",

  template: hbs`
      <a href={{attrs.shareUrl}} class="post-info arrow" title={{i18n "topic.jump_reply"}} aria-label={{i18n
        "topic.jump_reply_aria" username=attrs.name
      }}>
      {{#if attrs.above}}
        {{d-icon "arrow-up"}}
      {{else}}
        {{d-icon "arrow-down"}}
      {{/if}}
      {{i18n "topic.jump_reply_button"}}
      </a>
  `,
});

createWidget("poster-created_at", {
  tagName: "div.poster-created_at",
  html(attrs) {
    let htmlString=formatDate(attrs.created_at,{leaveAgo:"true"}).__string;
    const match = htmlString.match(/<span.*?>(.*?)<\/span>/);
    return match[1];
  }
});

export default createWidget("embedded-post", {
  tagName: "div.reply",
  buildKey: (attrs) => `embedded-post-${attrs.id}`,
  buildAttributes(attrs) {
    const attributes = { "data-post-id": attrs.id };
    if (this.state.role) {
      attributes.role = this.state.role;
    }
    if (this.state["aria-label"]) {
      attributes["aria-label"] = this.state["aria-label"];
    }
    return attributes;
  },
   defaultState(attrs) {
    const defaultState = {
      expandedFirstPost: false,
      repliesBelow: [],
    };

    if (this.siteSettings.enable_filtered_replies_view) {
      const topicController = this.register.lookup("controller:topic");

      if (attrs.post_number) {
        defaultState.filteredRepliesShown =
          topicController.replies_to_post_number ===
          attrs.post_number.toString();
      }
    }

    return defaultState;
  },

  html(attrs, state) {
    let post=[attrs]
    const lookup = EmberObject.create();
    const extraState = {
      state: {
        repliesShown: !!state.repliesBelow.length,
        filteredRepliesShown: state.filteredRepliesShown,
      },
    };

    attrs.embeddedPost = true;
    const repliesBelow = state.repliesBelow;
    return [
      h("div.row", [
        h("div.topic-body", [
          h("div.topic-meta-data.embedded-reply", [
            this.attach("post-avatar", attrs),
            this.attach("poster-name", attrs.asPost),
            // this.attach("poster-created_at", attrs),
            // this.attach("post-link-arrow", attrs),
          ]),
          new PostCooked(attrs, new DecoratorHelper(this), this.currentUser),
          this.attach("post-menu", attrs.asPost , extraState),
          h(
            `section.embedded-posts.bottom#embedded-posts__bottom--${this.attrs.post_number}`,
            [
              repliesBelow.map((p) => {
                return [
                    this.attach("embedded-post", p, {
                      model: p.asPost,
                      state: {
                        role: "region",
                        "aria-label": I18n.t("post.sr_embedded_reply_description", {
                          post_number: attrs.post_number,
                          username: p.username,
                        }),
                      },
                    }),
                ]
              })
            ]
          )
        ]),
      ]),
    ];
  },

  init() {
    this.postContentsDestroyCallbacks = [];
  },

  destroy() {
    this.postContentsDestroyCallbacks.forEach((c) => c());
  },
  toggleRepliesBelow(goToPost = "false") {
    if (this.state.repliesBelow.length) {
      this.state.repliesBelow = [];
      if (goToPost === "true") {
        DiscourseURL.routeTo(
          `${this.attrs.topicUrl}/${this.attrs.post_number}`
        );
      }
      return;
    }

    const post = this.findAncestorModel();
    const topicUrl = post ? post.get("topic.url") : null;
    return this.store
      .find("post-reply", { postId: this.attrs.id })
      .then((posts) => {
        this.state.repliesBelow = posts.map((p) => {
          let result = transformWithCallbacks(p);

          // these would conflict with computed properties with identical names
          // in the post model if we kept them.
          delete result.new_user;
          delete result.deleted;
          delete result.shareUrl;
          delete result.firstPost;
          delete result.usernameUrl;

          result.customShare = `${topicUrl}/${p.post_number}`;
          result.asPost = this.store.createRecord("post", result);
          return result;
        });
      });
  },
  toggleLike() {
    const post = this.attrs.asPost;
    const likeAction = post.get("likeAction");
    if (likeAction && likeAction.get("canToggle")) {
      return likeAction.togglePromise(post).then((result) => {
        this.appEvents.trigger("page:like-toggled", post, likeAction);
        return this._warnIfClose(result);
      });
    }
  },

  _warnIfClose(result) {
    if (!result || !result.acted) {
      return;
    }

    const kvs = this.keyValueStore;
    const lastWarnedLikes = kvs.get("lastWarnedLikes");

    // only warn once per day
    const yesterday = Date.now() - 1000 * 60 * 60 * 24;
    if (lastWarnedLikes && parseInt(lastWarnedLikes, 10) > yesterday) {
      return;
    }

    const { remaining, max } = result;
    const threshold = Math.ceil(max * 0.1);
    if (remaining === threshold) {
      this.dialog.alert(I18n.t("post.few_likes_left"));
      kvs.set({ key: "lastWarnedLikes", value: Date.now() });
    }
  },
});
