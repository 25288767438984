import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";

let template = compiler({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <label for=\"bulk-select-"
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":40}}}))
    + "\">\n      <input type=\"checkbox\" class=\"bulk-select\" id=\"bulk-select-"
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":6,"column":65},"end":{"line":6,"column":77}}}))
    + "\">\n    </label>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"topic-featured-link")||(depth0 && lookupProperty(depth0,"topic-featured-link"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic",{"name":"topic-featured-link","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":39,"column":6},"end":{"line":39,"column":37}}}));
},"7":function(container,depth0,helpers,partials,data) {
    return "<span class=\"topic-post-badges\">&nbsp;<span class=\"badge-notification new-topic\"></span></span>";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"list-imge\">\n      <img src="
    + container.escapeExpression(lookupProperty(helpers,"get").call(depth0 != null ? depth0 : (container.nullContext || {}),"topic.image_url",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":49,"column":15},"end":{"line":49,"column":34}}}))
    + " alt=\"\">\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"topic-list-data\">"
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-columns"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":57}}}))
    + "\n  <div class='pull-left'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"bulkSelectEnabled",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div class='topic-item-metadata right'>\n"
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-link"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":21,"column":58}}}))
    + "\n      <div class='main-link' style=\"width: 100%;\">\n        <div style=\"display: flex; align-items: center;margin-bottom: 7px;\">\n          <a style=\"margin-right: 5px;\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"latest_poster_link",{"name":"i18n","hash":{"username":"topic.creator.username"},"hashTypes":{"username":"PathExpression"},"hashContexts":{"username":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":24,"column":52},"end":{"line":24,"column":113}}}))
    + "\"\n            data-user-card=\""
    + alias3(lookupProperty(helpers,"get").call(alias1,"topic.creator.username",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":25,"column":54}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"avatar")||(depth0 && lookupProperty(depth0,"avatar"))||alias2).call(alias1,"topic.creator",{"name":"avatar","hash":{"imageSize":"large"},"hashTypes":{"imageSize":"StringLiteral"},"hashContexts":{"imageSize":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":25,"column":56},"end":{"line":25,"column":98}}}))
    + "</a>\n          <span class=\"usersnow topic-userinfo\">\n            <span class=\"usersnow_name topic-name\">"
    + alias3(lookupProperty(helpers,"get").call(alias1,"topic.creator.name",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":27,"column":51},"end":{"line":27,"column":73}}}))
    + "</span>\n            <span class=\"bumpedat\">\n              "
    + alias3((lookupProperty(helpers,"format-date")||(depth0 && lookupProperty(depth0,"format-date"))||alias2).call(alias1,"topic.created_at",{"name":"format-date","hash":{"leaveAgo":"true"},"hashTypes":{"leaveAgo":"StringLiteral"},"hashContexts":{"leaveAgo":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":29,"column":14},"end":{"line":29,"column":62}}}))
    + "\n            </span>\n            </span>\n        </div>\n      </div>"
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-status"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":35,"column":6},"end":{"line":35,"column":60}}}))
    + alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"topic-status",{"name":"raw","hash":{"topic":"topic"},"hashTypes":{"topic":"PathExpression"},"hashContexts":{"topic":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":36,"column":6},"end":{"line":36,"column":42}}}))
    + alias3((lookupProperty(helpers,"topic-link")||(depth0 && lookupProperty(depth0,"topic-link"))||alias2).call(alias1,"topic",{"name":"topic-link","hash":{"class":"raw-link raw-topic-link top-link mobile-link"},"hashTypes":{"class":"StringLiteral"},"hashContexts":{"class":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":37,"column":6},"end":{"line":37,"column":80}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.featured_link",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(5, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":38,"column":6},"end":{"line":40,"column":15}}})) != null ? stack1 : "")
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-after-title"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":41,"column":6},"end":{"line":41,"column":58}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.unseen",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(7, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "<div style=\"display: flex;align-items: center;width:100%;\">\n          "
    + alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"list.topic-excerpt",{"name":"raw","hash":{"topic":"topic"},"hashTypes":{"topic":"PathExpression"},"hashContexts":{"topic":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":46,"column":10},"end":{"line":46,"column":50}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.image_url",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(9, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":47,"column":10},"end":{"line":51,"column":9}}})) != null ? stack1 : "")
    + "      </div>"
    + alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"list-function",{"name":"raw","hash":{"topic":"topic"},"hashTypes":{"topic":"PathExpression"},"hashContexts":{"topic":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":53,"column":2},"end":{"line":53,"column":38}}}))
    + "\n  </div>\n\n</td>\n";
},"useData":true});

addRawTemplate("mobile/list/topic-list-item", template, { core: true });
export default template;