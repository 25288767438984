/* import __COLOCATED_TEMPLATE__ from './search-result-entries.hbs'; */
import Component from "@ember/component";
import { on } from "@ember/object/evented";
import { nativeShare } from "discourse/lib/pwa-utils";
import { getOwner } from "@ember/application";
import { ajax } from "discourse/lib/ajax";
import { service } from "@ember/service";
import ShareTopicModal from "discourse/components/modal/share-topic";
export default Component.extend({
  dialog:service(),
  tagName: "",
  topics:null,
  _init: on("init", function () {
  }),
  listfun() {
    const likeLinks = document.querySelectorAll('.click-likes');
    likeLinks.forEach(link => {
      link.addEventListener('click', (e)=>{
        const thumbsUpIcon = link.querySelector('.d-icon-thumbs-up');
        const likenum=link.querySelector('.likenum')
        const clickedLink = e.currentTarget;
        const topicId = clickedLink.getAttribute('data-topic-id');
        let topicitem=this.topics.filter(item=>item.relative_post_id==topicId)

        if(!topicId) return
        if(topicitem[0].liked==true){
         ajax("/post_actions/" + topicId, {
          type: "DELETE",
          data: { post_action_type_id: 2 },
         }).then((result) => {
          let topiclist=this.topics
          for (let i = 0; i < topiclist.length; i++) {
            if(topiclist[i].relative_post_id==topicId){
              topiclist[i].liked=false
            }
          }
          this.set('topics',topiclist)
         likenum.innerText=Number(likenum.innerText)-1
         thumbsUpIcon.style.setProperty('color', '#a3afba', 'important');
           }).catch(error => {
            this.dialog.alert({
              message:error.jqXHR.responseJSON.errors[0]
            });
           });
        }
        if(topicitem[0].liked==false||topicitem[0].liked==null){
           ajax("/post_actions", {
            type: "POST",
            data: {
              id: topicId,
              post_action_type_id: 2,
              flag_topic:  false,
            },
            returnXHR: true,
          }).then(res=>{
            let topiclist=this.topics
            for (let i = 0; i < topiclist.length; i++) {
              if(topiclist[i].relative_post_id==topicId){
                topiclist[i].liked=true
              }
            }
            this.set('topics',topiclist)
          likenum.innerText=Number(likenum.innerText)+1
          thumbsUpIcon.style.setProperty('color', 'red', 'important');
          thumbsUpIcon.classList.add('animate');
          thumbsUpIcon.addEventListener('animationend', () => {
          thumbsUpIcon.classList.remove('animate');
          }, { once: true });
          }).catch(error => {
            this.dialog.alert({
              message:error.jqXHR.responseJSON.errors[0]
            });
           });
        }

      });
    });

    const Clickshare = document.querySelectorAll('.click-share');
    if(Clickshare){
      Clickshare.forEach(link => {
        link.addEventListener('click', (e)=>{
          const clickedLink = e.currentTarget;
          const share = clickedLink.getAttribute('data-topic-id');
          const slug = clickedLink.getAttribute('data-topic-slug');
          let url=`/t/${slug}/${share}`
          let topicitem=this.topics.filter(item=>item.id==share)
          nativeShare(this.capabilities, { url: url }).catch(() => {
            getOwner(this)
              .lookup("service:modal")
              .show(ShareTopicModal, {
                model: { category: topicitem[0].category, topic:topicitem[0] },
              });
          });
        });
      });
    }

  },
});
