import hbs from "discourse/widgets/hbs-compiler";
import { createWidget } from "discourse/widgets/widget";

createWidget("header-contents", {
  tagName: "div.contents",
  transform() {
    return {
      showBootstrapMode: this.currentUser?.staff && this.site.desktopView,
    };
  },
  template: hbs`
    {{#if this.site.desktopView}}
      {{#if attrs.sidebarEnabled}}
        {{sidebar-toggle attrs=attrs}}
      {{/if}}
    {{/if}}

    {{before-header-logo-outlet attrs=attrs}}

    {{home-logo-wrapper-outlet attrs=attrs}}

    {{#if attrs.topic}}
      {{header-topic-info attrs=attrs}}
    {{else if this.siteSettings.bootstrap_mode_enabled}}
      {{#if transformed.showBootstrapMode}}
        {{header-bootstrap-mode attrs=attrs}}
      {{/if}}
    {{/if}}

    {{before-header-panel-outlet attrs=attrs}}

    <div class="panel" role="navigation">{{yield}}</div>

    {{after-header-panel-outlet attrs=attrs}}
  `,
});
const mobileUserAgent = /Mobi|Android/i.test(navigator.userAgent);
if(mobileUserAgent || window.innerWidth <= 768){
}else{
setTimeout(function () {
let topnavbar= document.getElementsByClassName('top-navbar')[0]
if(topnavbar){
  var menuElements= topnavbar.getElementsByTagName('a');

  for (var i = 0; i < menuElements.length; i++) {
    menuElements[i].addEventListener('click', function () {
      for (var j = 0; j < menuElements.length; j++) {
        menuElements[j].classList.remove('active-Tail');
      }
      this.classList.add('active-Tail');
    });
  }
  var menu = document.getElementsByClassName('red-button')
  for (var i = 0; i < menu.length; i++) {
    menu[i].addEventListener('click', function () {
      for (var j = 0; j < menuElements.length; j++) {
        menuElements[j].classList.remove('active-Tail');
      }
    });
  }
  var search = document.getElementsByClassName('search-icon')
  for (var i = 0; i < search.length; i++) {
    search[i].addEventListener('click', function () {
      for (var j = 0; j < menuElements.length; j++) {
        menuElements[j].classList.remove('active-Tail');
      }
    });
  }
  var logobig = document.getElementsByClassName('logo-big')
  for (var i = 0; i < logobig.length; i++) {
    logobig[i].addEventListener('click', function () {
      for (var j = 0; j < menuElements.length; j++) {
        menuElements[j].classList.remove('active-Tail');
      }
    });
  }
}
}, 1000);
}



