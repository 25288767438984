import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { eq, not, or } from "truth-helpers";
import DAG from "discourse/lib/dag";
import getURL from "discourse-common/lib/get-url";
import Dropdown from "./dropdown";
import UserDropdown from "./user-dropdown";
import { action } from "@ember/object";
import { on } from "@ember/modifier";
import Category from "discourse/models/category";
import Composer from "discourse/models/composer";
import Notifications from "./user-dropdown/notifications";
let headerIcons;
resetHeaderIcons();
function resetHeaderIcons() {
    headerIcons = new DAG({
        defaultPosition: {
            before: "search"
        }
    });
    headerIcons.add("search");
    headerIcons.add("hamburger", undefined, {
        after: "search"
    });
    headerIcons.add("user-menu", undefined, {
        after: "hamburger"
    });
}
export function headerIconsDAG() {
    return headerIcons;
}
export function clearExtraHeaderIcons() {
    resetHeaderIcons();
}
export default class Icons extends Component {
    @service
    site;
    @service
    currentUser;
    @service
    header;
    @service
    search;
    @service
    composer;
    @service
    router;
    @service
    dialog;
    //   get showHamburger() {
    //   // NOTE: In this scenario, we are forcing the sidebar on admin users,
    //   // so we need to still show the hamburger menu to be able to
    //   // access the legacy hamburger forum menu.
    //   if (
    //     this.args.sidebarEnabled &&
    //     this.sidebarState.adminSidebarAllowedWithLegacyNavigationMenu
    //   ) {
    //     return true;
    //   }
    //   return !this.args.sidebarEnabled || this.site.mobileView;
    // }
    // @action
    // toggleHamburger() {
    //   if (this.sidebarState.adminSidebarAllowedWithLegacyNavigationMenu) {
    //     this.args.toggleNavigationMenu("hamburger");
    //   } else {
    //     this.args.toggleNavigationMenu();
    //   }
    // }
    @action
    opennewtopic() {
        if (this.currentUser) {
            const filteredGroups1 = this.currentUser.groups.filter((group1)=>group1.name.slice(0, 5) !== "trust");
            if (filteredGroups1.length == 0) {
                this.dialog.alert("You have not joined any groups yet~ / or please first join a certain interest group before posting~");
            } else {
                window.localStorage.removeItem("categoryId");
                if (this.isMobile) {
                    this.composer.openNewTopic({
                        preferDraft: true,
                        category: {
                            id: ""
                        }
                    });
                } else {
                    this.router.transitionTo("newtopic");
                    this.composer.open({
                        action: Composer.CREATE_TOPIC,
                        draftKey: Composer.NEW_TOPIC_KEY,
                        replyflg: true,
                        categoryId: ""
                    });
                }
            }
        } else {
            localStorage.setItem("previousPage", window.location.href);
            // this.router.transitionTo("login");
            this.router.transitionTo("/userlogin");
        }
    }
    get isMobile() {
        // 这是一个简单的正则表达式来检测常见的移动端User Agent。
        // 在实际应用中，你可能需要使用更全面的库或服务来检测设备。
        const mobileUserAgent1 = /Mobi|Android/i.test(navigator.userAgent);
        return mobileUserAgent1 || window.innerWidth <= 768; // 也可以结合屏幕宽度判断
    }
    get msgScss() {
        if (this.currentUser.all_unread_notifications_count > 0 || this.currentUser.new_personal_messages_notifications_count > 0 || this.currentUser.unseen_reviewable_count > 0) {
            return true;
        }
        return false;
    }
    static{
        template(`
    <ul class="icons d-header-icons">
      {{#each (headerIcons.resolve) as |entry|}}
        {{#if (eq entry.key "search")}}
          {{#if (not this.isMobile)}}
            <Dropdown
              @title="search.title"
              @icon="search"
              @iconId={{@searchButtonId}}
              @onClick={{@toggleSearchMenu}}
              @active={{this.search.visible}}
              @href={{getURL "/search"}}
              @className="search-dropdown"
              @targetSelector=".search-menu-panel"
            />
          {{/if}}
        {{else if (eq entry.key "hamburger")}}

          {{#if this.isMobile}}
            <Dropdown
              @title="search.title"
              @icon="search"
              @iconId={{@searchButtonId}}
              @onClick={{@toggleSearchMenu}}
              @active={{this.search.visible}}
              @href={{getURL "/search"}}
              @className="search-dropdown"
              @targetSelector=".search-menu-panel"
            />
            {{#if this.currentUser}}
              {{!-- <UserDropdown
                @active={{this.header.userVisible}}
                @toggleUserMenu={{@toggleUserMenu}}
              /> --}}
            {{/if}}
            <Dropdown
              @title="hamburger_menu"
              @icon="bars"
              @iconId="toggle-hamburger-menu"
              @active={{this.header.hamburgerVisible}}
              @onClick={{@toggleHamburger}}
              @className="hamburger-dropdown"
            />
          {{else}}
            {{#if this.showHamburger}}
              <Dropdown
                @title="hamburger_menu"
                @icon="bars"
                @iconId="toggle-hamburger-menu"
                @active={{this.header.hamburgerVisible}}
                @onClick={{this.toggleHamburger}}
                @className="hamburger-dropdown"
              />
            {{/if}}
          {{/if}}
          {{!-- {{#if this.showHamburger}}
          {{#if (or (not @sidebarEnabled) this.site.mobileView)}}
          {{#if this.isMobile}}
          <Dropdown
            @title="hamburger_menu"
            @icon="bars"
            @iconId="toggle-hamburger-menu"
            @active={{this.header.hamburgerVisible}}
            @onClick={{@toggleHamburger}}
            @className="hamburger-dropdown"
          />
          {{/if}} --}}
        {{else if (eq entry.key "user-menu")}}
          {{#if this.currentUser}}
            <div class="container-header">
              {{! <div class="search-icon">
                <a href="/search">
                  <img src="/images/Search.jpg" alt="Search Icon" />
                </a>
              </div> }}
              <a class="red-button" {{on "click" this.opennewtopic}}>
                <img src="/images/Add.jpg" alt="Add Icon" />
                <span>
                  NEW TOPIC
                </span>
              </a>
              <a href="/notification" rel="noopener noreferrer" class='pc-msg'>
                <img
                  class="nav-msg"
                  src="/images/moyun_msg.png"
                  alt="message1"
                />
                {{#if this.msgScss}}
                <div class="pc-dot"></div>
                {{/if}}
              </a>

              {{!-- <a href="/notification" rel="noopener noreferrer" class='zhoumo'>
                <button
                  class="icon btn-flat snowBtn sn"
                  aria-haspopup="true"
                  aria-expanded={{this.header.userVisible}}
                >
                  <img
                    class="nav-msg"
                    src="/images/moyun_msg.png"
                    alt="message1"
                  />
                  <Notifications @active={{this.header.userVisible}} />
                </button>
              </a> --}}

              <UserDropdown
                @active={{this.header.userVisible}}
                @toggleUserMenu={{@toggleUserMenu}}
              />
            </div>
          {{/if}}
        {{else if entry.value}}
          {{! <entry.value /> }}
        {{/if}}
      {{/each}}
    </ul>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
